import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import about from '../DATAS/aboutDatasFR.json';
import contact from '../DATAS/contactDatasFR';
import portfolio from '../DATAS/portfolioDatasFR.json';
import projects from '../DATAS/projectsDatasFR.json';
import projectsEN from '../DATAS/projectsDatasEN.json';
import footer from '../DATAS/footerDatasFR';
import footerEN from '../DATAS/footerDatasEN';
import aboutEN from '../DATAS/aboutDatasEN.json';
import contactEN from '../DATAS/contactDatasEN';
import portfolioEN from '../DATAS/portfolioDatasEN.json';
import privacyPolicy from '../DATAS/privacyPoliciesDatas';
import privacyPolicyEN from '../DATAS/privacyPoliciesDatasEN';
import legalMentions from '../DATAS/legalMentionsDatas';
import legalMentionsEN from '../DATAS/legalMentionsDatasEN';
import DSLPolicy from '../DATAS/DailyShopList/DSLPolicyDatas';
import DSLPolicyEN from '../DATAS/DailyShopList/DSLPolicyDatasEN';
import DSLNotice from '../DATAS/DailyShopList/DSLNoticeDatas';
import DSLNoticeEN from '../DATAS/DailyShopList/DSLNoticeDatasEN';
import DSLMentions from '../DATAS/DailyShopList/DSLMentionsDatas';
import DSLMentionsEN from '../DATAS/DailyShopList/DSLMentionsDatasEN';

const DatasContext = createContext();

export const DatasProvider = ({ children }) => {
  const [appIsReady, setAppIsReady] = useState(false);
  const [fontsLoaded, setFontsLoaded] = useState(false);

  const [datas, setDatas] = useState({
    portfolio: null,
    about: null,
    contact: null,
    projects: null,
    footer: null,
    privacyPolicy: null,
    legalMentions: null,
    DSLPolicy: null,
    DSLNotice: null,
    DSLMentions: null
  });

  const [isLoading, setIsLoading] = useState({
    portfolio: true,
    about: true,
    contact: true,
    projects: true,
    footer: true,
    privacyPolicy: true,
    legalMentions: true,
    DSLPolicy: true,
    DSLNotice: true,
    DSLMentions: true
  });

  const [error, setError] = useState(
    {
      portfolio: null,
      about: null,
      contact: null,
      projects: null,
      footer: null,
      privacyPolicy: null,
      legalMentions: null,
      DSLPolicy: null,
      DSLNotice: null,
      DSLMentions: null
    });

  const getBrowserLanguage = () => {
    const language = navigator.language || navigator.userLanguage;

    return language.startsWith('fr') ? 'fr' : 'en';
  };

  const locale = getBrowserLanguage();

  useEffect(() => {
    document.fonts.ready.then(() => setFontsLoaded(true));
  }, []);

  const loadData = useCallback((key, frData, enData) => {
    if (isLoading[key]) {
      try {
        const data = locale === 'fr' ? frData : enData;
        setDatas(prev => ({ ...prev, [key]: data }));
      } catch (err) {
        console.error(`Error loading ${key} data: `, err);
        setError(prev => ({ ...prev, [key]: err }));
      } finally {
        setIsLoading(prev => ({ ...prev, [key]: false }));
      }
    }
  }, [isLoading, locale]);

  useEffect(() => {

    loadData('portfolio', portfolio.data, portfolioEN.data);
    loadData('about', about.data, aboutEN.data);
    loadData('contact', contact.data, contactEN.data);
    loadData('projects', projects.data, projectsEN.data);
    loadData('footer', footer.data, footerEN.data);
    loadData('privacyPolicy', privacyPolicy, privacyPolicyEN);
    loadData('legalMentions', legalMentions, legalMentionsEN);
    loadData('DSLPolicy', DSLPolicy, DSLPolicyEN);
    loadData('DSLNotice', DSLNotice, DSLNoticeEN);
    loadData('DSLMentions', DSLMentions, DSLMentionsEN);
    setAppIsReady(true);
  }, [locale, loadData]);

  return (
    <DatasContext.Provider value={{ datas, isLoading, error, fontsLoaded, appIsReady }}>
      {children}
    </DatasContext.Provider>
  );
};

export const useDatasContext = () => useContext(DatasContext);
