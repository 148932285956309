import { memo } from 'react';
import { useDatasContext } from '../../../../context/DatasProvider.js';
import CanvasThree from '../../../../components/CanvasThree.jsx';
import Footer from '../../../../components/Footer/index.jsx';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

const DSLMentions = () => {
    const { datas, isLoading, error } = useDatasContext();

    if (!datas.DSLMentions || !datas.DSLMentions.blocks) {
        return (
            <CanvasThree
                location={'/'}
                loading={isLoading.DSLMentions ?? true}
                error={error.DSLMentions ?? undefined}
            />
        );
    }

    return (
        <>
            <CanvasThree
                loading={isLoading.DSLMentions}
                error={error.DSLMentions}
            />
            <main className='privacy-container'>
                <h1 className='privacy-title'>{datas.DSLMentions.title}</h1>
                {datas.DSLMentions.blocks.map((section, index) => (
                    section.type === 'introduction' ? (
                        <div key={index}>
                            {/* <h2 className='section-title'>{section.title}</h2> */}
                            <div className='section-text'>
                                <BlocksRenderer
                                    blocks={{
                                        paragraph: ({ children }) => (
                                            <div style={{
                                                width: '100%',
                                                marginBottom: '4rem'
                                            }}>
                                                {children.map((child) => (<p>{child}</p>))}
                                            </div>)
                                    }}
                                    content={section.content}
                                />
                            </div>
                        </div>
                    )
                        : section.type === "section" ? (
                            <div key={index}>
                                <h2 className="section-title">{section.attributes.title}</h2>
                                <div className="section-text">
                                    <BlocksRenderer
                                        blocks={{
                                            paragraph: ({ children }) => (
                                                <div style={{ width: "100%", marginBottom: "4rem" }}>
                                                    {children.map((child, i) => (
                                                        <p key={i}>{child}</p> // 🔥 Ici, on ne manipule pas `child`, on le rend directement !
                                                    ))}
                                                </div>
                                            ),
                                            list: ({ children, format }) => {
                                                console.log("children (list):", children);
                                                return format === "unordered" ? (
                                                    <ul>
                                                        {children.map((child, i) => {
                                                            console.log("👉 child (list-item):", child);
                                                            return <li key={i} className='list-item'>{child}</li>;
                                                        })}
                                                    </ul>
                                                ) : (
                                                    <ol>
                                                        {children.map((child, i) => (
                                                            <li key={i} className='list-item'>{child}</li>
                                                        ))}
                                                    </ol>
                                                );
                                            },
                                            link: ({ children, url }) => (
                                                <a href={url} target="_blank" rel="noopener noreferrer" className="legal-link">
                                                    {children}
                                                </a>
                                            ),
                                        }}
                                        modifiers={{
                                            bold: ({ children }) => <strong>{children}</strong>,
                                            italic: ({ children }) => <em>{children}</em>,
                                            underline: ({ children }) => <u>{children}</u>,
                                            strikethrough: ({ children }) => <s>{children}</s>,
                                        }}
                                        content={section.content}
                                    />
                                </div>
                            </div>
                        )
                            : null
                ))}
            </main >
            {isLoading.DSLMentions || error.DSLMentions ? null : <Footer />}
        </>
    );
};

export default memo(DSLMentions);