import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useDatasContext } from './context/DatasProvider';
import Portfolio from './pages/Portfolio';
import About from './pages/About';
import Contact from './pages/Contact';
import Navbar from './components/Navbar';
import './App.scss';
import './helpers/helpers.scss';
import './helpers/reset.scss';
import PrivacyPolicies from './pages/LEGAL/PrivacyPolicies';
import LegalMentions from './pages/LEGAL/LegalMentions';
import DSLPolicy from './pages/LEGAL/DSL/DSLPolicy';
import DSLNotice from './pages/LEGAL/DSL/DSLNotice';
import DSLMentions from './pages/LEGAL/DSL/DSLMentions';
import NotFound from './pages/NotFound';

function App() {
  const { appIsReady } = useDatasContext();

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0)
    }, [pathname]);



    return null;

  };
  if (!appIsReady) {
    return null;
  }

  return (
    <>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={<Portfolio />}
        />
        <Route
          path="/about/*"
          element={<About />}
        />
        <Route
          path="/contact/*"
          element={<Contact />}
        />
        <Route
          path="/policy/*"
          element={<PrivacyPolicies />}
        />
        <Route
          path="/notice/*"
          element={<LegalMentions />}
        />
        <Route
          path="dailyshoplist/policy/*"
          element={<DSLPolicy />}
        />
        <Route
          path="dailyshoplist/notice/*"
          element={<DSLNotice />}
        />
        <Route
          path="dailyshoplist/mentions/*"
          element={<DSLMentions />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
