const DSLPolicyDatas = {
  title: "Privacy Policy",
  blocks: [
    {
      type: "introduction",
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `At ${process.env.REACT_APP_COMPANY_NAME || "Company Name"}, we are committed to protecting your privacy. This privacy policy explains how we collect, use, and safeguard the information when you use our mobile application.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "1. Information Collection" },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `${process.env.REACT_APP_APP_NAME || "Application Name"} does not collect sensitive personal data such as your name, email address, or phone number.`
            }
          ]
        },
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `The application uses ${process.env.REACT_APP_STORAGE_LIBRARY || 'WatermelonDB'} to locally store user-related information. This data is not sent to external servers and remains on your device.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "2. Advertising and Consent" },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Our application displays advertisements via ${process.env.REACT_APP_AD_PROVIDER || 'Google AdMob'}. We use the Google User Messaging Platform (UMP) to collect your consent in accordance with applicable regulations (GDPR in Europe, CCPA in California, PIPEDA in Canada, etc.).`
            }
          ]
        },
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: "On your first use, a consent form will appear allowing you to accept or refuse personalized ads. You can change your preference at any time using the 'Manage my advertising preferences' button in the app settings."
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "3. Use of Cookies and Advertising Identifiers" },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: "We use advertising identifiers and similar technologies to enhance your experience and optimize our advertisements. You can manage these settings by disabling personalized ads in your device settings."
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "4. Data Sharing with Third Parties" },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `We do not share any personal data with third parties. However, ads displayed via ${process.env.REACT_APP_AD_PROVIDER || 'Google AdMob'} are subject to their own privacy policy.`
            }
          ]
        },
        {
          type: "link",
          url: "https://policies.google.com/technologies/ads",
          children: [
            {
              type: "text",
              text: "Google AdMob's Privacy Policy"
            }
          ]
        },
      ]
    },
    {
      type: "section",
      attributes: { title: "5. Consent Management and Revocation" },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: "You can modify your consent at any time via the 'Manage my advertising preferences' button available in the app settings."
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "6. Your Rights by Country of Residence" },
      content: [
        {
          type: "paragraph",
          children: [
            { type: "text", text: "🇪🇺 **Europe (GDPR)**: Right to access, rectify, delete, portability, and object to processing." },
            { type: "text", text: "🇨🇦 **Canada (PIPEDA)**: Right to access and correct your data, and refuse targeted advertising." },
            { type: "text", text: "🇦🇺 **Australia (Privacy Act 1988)**: Right to access personal information and request deletion." },
            { type: "text", text: "🇮🇳 **India (DPDP Act 2023)**: Right to refuse data collection and access collected information." },
            { type: "text", text: "🇺🇸 **United States (CCPA - California)**: Right to request collected data, refuse the sale of personal data, and request deletion." }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "7. Deleting and Resetting Advertising Data" },
      content: [
        {
          type: "paragraph",
          children: [
            { type: "text", text: "You can reset your advertising ID via your device settings:" },
            { type: "text", text: "- 📱 **Android**: Settings > Google > Ads > Reset Advertising ID." },
            { type: "text", text: "- 🍏 **iOS**: Settings > Privacy > Advertising > Reset Advertising Identifier." }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "8. Contact" },
      content: [
        {
          type: "paragraph",
          children: [
            { type: "text", text: `If you have any questions, please contact us at: ${process.env.REACT_APP_CONTACT_EMAIL || 'contact@example.com'}.` }
          ]
        }
      ]
    }
  ]
};

export default DSLPolicyDatas;