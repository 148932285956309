import { memo } from 'react';
import { useDatasContext } from '../../../../context/DatasProvider.js';
import CanvasThree from '../../../../components/CanvasThree.jsx';
import Footer from '../../../../components/Footer/index.jsx';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

const DSLNoticePage = () => {
    const { datas, isLoading, error } = useDatasContext();

    if (!datas?.DSLNotice || !datas.DSLNotice.blocks) {
        return (
            <CanvasThree
                location={'/'}
                loading={isLoading.DSLNotice ?? true}
                error={error.DSLNotice ?? undefined}
            />
        );
    }

    return (
        <>
            <CanvasThree
                loading={isLoading.DSLNotice}
                error={error.DSLNotice}
            />
            <main className='privacy-container'>
                <h1 className='privacy-title'>{datas.DSLNotice.title}</h1>
                {datas.DSLNotice.blocks.map((section, index) => (
                    section.type === 'introduction' ? (
                        <div key={index}>
                            {/* <h2 className='section-title'>{section.title}</h2> */}
                            <div className='section-text'>
                                <BlocksRenderer
                                    blocks={{
                                        paragraph: ({ children, index }) => (
                                            <div key={index} style={{
                                                width: '100%',
                                                marginBottom: '4rem'
                                            }}>
                                                {children.map((child, index) => (<p key={index} >{child}</p>))}
                                            </div>)
                                    }}
                                    content={section.content}
                                />
                            </div>
                        </div>
                    )
                        : section.type === 'section' ? (
                            <div key={index}>
                                <h2 className='section-title'>{section.attributes.title}</h2>
                                <div className='section-text'>
                                    <BlocksRenderer
                                        blocks={{
                                            paragraph: ({ children, index }) => (
                                                <div key={index} style={{
                                                    width: '100%',
                                                    marginBottom: '4rem'
                                                }}>
                                                    {children.map((child, index) => (<p key={index} >{child}</p>))}
                                                </div>)
                                        }}
                                        content={section.content}
                                    />
                                </div>
                            </div>
                        )
                            : null
                ))}
            </main>
            {isLoading.DSLNotice || error.DSLNotice ? null : <Footer />}
        </>
    );
};

export default memo(DSLNoticePage);
