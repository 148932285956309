import { credits } from "../../constants/Credits";

const DSLMentions = {
  title: "Mentions Légales",
  blocks: [
    {
      type: "introduction",
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Mentions légales de l'application ${process.env.REACT_APP_APP_NAME || 'Daily Shop List'}.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "1. Éditeur" },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `L'application ${process.env.REACT_APP_APP_NAME || 'Daily Shop List'} est éditée par ${process.env.REACT_APP_COMPANY_NAME || 'Nom de l\'éditeur'}, située à ${process.env.REACT_APP_COMPANY_ADDRESS || 'Adresse de l\'éditeur'}.`
            }
          ]
        },
        {
          type: "paragraph",
          children: [
            { type: "text", text: `Statut : ${process.env.REACT_APP_COMPANY_STATUS || 'Micro-entrepreneur'}` },
            { type: "text", text: `SIRET : ${process.env.REACT_APP_COMPANY_SIRET || 'Numéro de SIRET'}` },
            { type: "text", text: `Contact : ${process.env.REACT_APP_CONTACT_EMAIL || 'contact@gzreactives.com'}` }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "2. Propriété Intellectuelle" },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Tous les éléments de l'application, y compris les textes, logos et icônes, sont protégés par les lois sur la propriété intellectuelle et sont la propriété exclusive de ${process.env.REACT_APP_COMPANY_NAME || 'Nom de l\'éditeur'}, sauf indication contraire. Les icônes de cette application proviennent de '@expo/vector-icons' et de Flaticon (https://www.flaticon.com), regroupant des bibliothèques d'icônes open-source. Le logo de l'application a été généré à l'aide des modèles ChatGPT et DALL-E d'OpenAI.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "3. Crédits des icônes Flaticon" },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: "Les icônes utilisées dans cette application proviennent de Flaticon et sont utilisées sous licence avec attribution obligatoire. Voici les auteurs et les liens vers les icônes :"
            }
          ]
        },
        {
          type: "list",
          format: "unordered",
          children:
            credits.map(icon => ({
              type: "list-item",
              children: [
                {
                  type: "text",
                  text: `🔗 ${icon.description} - `,
                },
                {
                  type: "link",
                  url: icon.link,
                  children: [{ type: "text", text: "Voir l'icône sur Flaticon" }]
                }
              ]
            }))
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "4. Licence des Polices" },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `L'application utilise la police 'Quicksand', sous licence SIL Open Font License, Version 1.1. Détails sur : https://scripts.sil.org/OFL.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "5. Responsabilité" },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `${process.env.REACT_APP_COMPANY_NAME || 'Nom de l\'éditeur'} ne peut être tenue responsable des dommages directs ou indirects résultant de l'utilisation de l'application.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "6. Contact" },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Pour toute question, veuillez contacter ${process.env.REACT_APP_COMPANY_NAME || 'Nom de l\'éditeur'} à ${process.env.REACT_APP_CONTACT_EMAIL || 'Adresse de contact'}.`
            }
          ]
        }
      ]
    }
  ]
};
export default DSLMentions;