export const credits = [
  // boissons
  {
    id: 1,
    name: "monkik",
    link: "https://www.flaticon.com/fr/icones-gratuites/breuvages",
    title: "breuvages icônes",
    description: "Breuvages icônes créées par monkik - Flaticon"
  },
  // nourriture pour animaux
  {
    id: 2,
    name: "noomtah",
    link: "https://www.flaticon.com/fr/icones-gratuites/la-nourriture-pour-animaux",
    title: "la nourriture pour animaux icônes",
    description: "La nourriture pour animaux icônes créées par noomtah - Flaticon"
  },
  // viandes et poissons
  {
    id: 3,
    name: "Smashicons",
    link: "https://www.flaticon.com/free-icons/protein",
    title: "protein icons",
    description: "Protein icons created by Smashicons - Flaticon"
  },
  // épicerie
  {
    id: 4,
    name: "Freepik",
    link: "https://www.flaticon.com/free-icons/can",
    title: "canned food icons",
    description: "Canned food icons created by Freepik - Flaticon"
  },
  // boulangerie
  {
    id: 5,
    name: "Freepik",
    link: "https://www.flaticon.com/fr/icones-gratuites/boulangerie",
    title: "boulangerie icônes",
    description: "Boulangerie icônes créées par Freepik - Flaticon"
  },
  // produits laitiers
  {
    id: 6,
    name: "Freepik",
    link: "https://www.flaticon.com/fr/icones-gratuites/les-produits-laitiers",
    title: "les produits laitiers icônes",
    description: "Les produits laitiers icônes créées par Freepik - Flaticon"
  },
  //  baby bottle
  {
    id: 7,
    name: "juicy_fish",
    link: "https://www.flaticon.com/fr/icones-gratuites/biberon",
    title: "biberon icônes",
    description: "Biberon icônes créées par juicy_fish - Flaticon"
  },
  // des surgelés
  {
    id: 8,
    name: "Freepik",
    link: "https://www.flaticon.com/fr/icones-gratuites/des-surgeles",
    title: "des surgelés icônes",
    description: "Des surgelés icônes créées par Freepik - Flaticon"
  },
  // produits d'hygiene
  {
    id: 9,
    name: "Freepik",
    link: "https://www.flaticon.com/fr/icones-gratuites/hygiene",
    title: "produits d'hygiène",
    description: "Produits d'hygiene icônes créées par Freepik - Flaticon"
  },
  // fruits et légumes
  {
    id: 10,
    name: "Freepik",
    link: "https://www.flaticon.com/fr/icones-gratuites/fruits-et-legumes",
    title: "fruits et légumes",
    description: "Fruits et légumes icônes créées par Freepik - Flaticon"
  },
]

// < a href = "https://www.flaticon.com/fr/icones-gratuites/breuvages" title = "breuvages icônes" > Breuvages icônes créées par monkik - Flaticon</a >

// < a href = "https://www.flaticon.com/fr/icones-gratuites/la-nourriture-pour-animaux" title = "la nourriture pour animaux icônes" > La nourriture pour animaux icônes créées par noomtah - Flaticon</a >

// < a href = "https://www.flaticon.com/free-icons/protein" title = "protein icons" > Protein icons created by Smashicons - Flaticon</a >

// <a href="https://www.flaticon.com/free-icons/can" title="can icons">Can icons created by Freepik - Flaticon</a>

// < a href = "https://www.flaticon.com/fr/icones-gratuites/boulangerie" title = "boulangerie icônes" > Boulangerie icônes créées par Freepik - Flaticon</a >

// <a href="https://www.flaticon.com/fr/icones-gratuites/les-produits-laitiers" title="les produits laitiers icônes">Les produits laitiers icônes créées par Freepik - Flaticon</a>

// < a href = "https://www.flaticon.com/fr/icones-gratuites/biberon" title = "biberon icônes" > Biberon icônes créées par juicy_fish - Flaticon</a >

// <a href="https://www.flaticon.com/fr/icones-gratuites/des-surgeles" title="des surgelés icônes">Des surgelés icônes créées par Freepik - Flaticon</a>