import { credits } from "../../constants/Credits";

const DSLMentions = {
  title: "Legal Notice",
  blocks: [
    {
      type: "introduction",
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Legal notice for the ${process.env.REACT_APP_APP_NAME || 'Daily Shop List'} application.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "1. Publisher" },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `The ${process.env.REACT_APP_APP_NAME || 'Daily Shop List'} application is published by ${process.env.REACT_APP_COMPANY_NAME || 'Publisher Name'}, located at ${process.env.REACT_APP_COMPANY_ADDRESS || 'Publisher Address'}.`
            }
          ]
        },
        {
          type: "paragraph",
          children: [
            { type: "text", text: `Statut : ${process.env.REACT_APP_COMPANY_STATUS || 'Micro-entrepreneur'}` },
            { type: "text", text: `SIRET : ${process.env.REACT_APP_COMPANY_SIRET || 'Numéro de SIRET'}` },
            { type: "text", text: `Contact : ${process.env.REACT_APP_CONTACT_EMAIL || 'contact@gzreactives.com'}` }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "2. Intellectual Property" },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `All elements of the application, including texts, logos, and icons, are protected by intellectual property laws and are the exclusive property of ${process.env.REACT_APP_COMPANY_NAME || 'Publisher Name'}, unless otherwise stated. Icons in this application come from '@expo/vector-icons' and Flaticon (https://www.flaticon.com). The app logo was generated using OpenAI’s ChatGPT and DALL-E models.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "3. Flaticon Icon Credits" },
      content: [
        {
          type: "list",
          format: "unordered",
          children:
            credits.map(icon => ({
              type: "list-item",
              children: [
                {
                  type: "text",
                  text: `🔗 ${icon.description} - `,
                },
                {
                  type: "link",
                  url: icon.link,
                  children: [{ type: "text", text: "View on Flaticon" }]
                }
              ]
            }))
        }
      ]
    }
  ]
};
export default DSLMentions;