import { memo } from 'react';
import { useDatasContext } from '../../../../context/DatasProvider.js';
import CanvasThree from '../../../../components/CanvasThree.jsx';
import Footer from '../../../../components/Footer';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

const DSLPolicy = () => {
    const { datas, isLoading, error } = useDatasContext();

    if (!datas.DSLPolicy || !datas.DSLPolicy.blocks) {
        return (
            <CanvasThree
                location={'/'}
                loading={isLoading.DSLPolicy ?? true}
                error={error.DSLPolicy ?? undefined}
            />
        );
    }

    return (
        <>
            <CanvasThree
                loading={isLoading.DSLPolicy}
                error={error.DSLPolicy}
            />
            <main className='privacy-container'>
                <h1 className='privacy-title'>{datas.DSLPolicy.title}</h1>
                {datas.DSLPolicy.blocks.map((section, indexBlock) => (
                    section.type === 'introduction' ? (
                        <div key={indexBlock}>
                            <h2 className='section-title'>{section.title}</h2>
                            <div className='section-text'>
                                <BlocksRenderer
                                    blocks={{
                                        paragraph: ({ children }) => {
                                            let randomNumber = Math.floor(Math.random() * 10);
                                            return (
                                                <div key={randomNumber} style={{
                                                    width: '100%',
                                                    marginBottom: '4rem'
                                                }}>
                                                    {children.map((child, index) => {
                                                        let anotherRandomNumber = Math.floor(Math.random() * 100);

                                                        return (<p key={index + '' + randomNumber + '' + anotherRandomNumber}>{child}</p>)
                                                    })}
                                                </div>
                                            )
                                        }
                                    }}
                                    content={section.content}
                                />
                            </div>
                        </div>
                    ) : section.type === 'section' ? (
                        <div key={indexBlock}>
                            <h2 className='section-title'>{section.attributes.title}</h2>
                            <div className='section-text'>
                                <BlocksRenderer
                                    blocks={{
                                        paragraph: ({ children, index }) => {
                                            return (
                                                <div key={index} style={{
                                                    width: '100%',
                                                    marginBottom: '4rem'
                                                }}>
                                                    {children.map((child, index) => {
                                                        return (
                                                            <p key={index}>{child}</p>
                                                        );
                                                    })}
                                                </div>
                                            )
                                        },
                                        link: ({ children, url }) => {
                                            return (
                                                <p>
                                                    <a
                                                        href={url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='legal-link'
                                                    >
                                                        {children}
                                                    </a>
                                                </p>
                                            )
                                        }
                                    }}
                                    content={section.content}
                                />
                            </div>
                        </div>
                    ) : null
                ))}
            </main>
            {isLoading.DSLPolicy || error.DSLPolicy ? null : <Footer />}
        </>
    );
};

export default memo(DSLPolicy);