const DSLPolicyDatas = {
  title: "Politique de Confidentialité",
  blocks: [
    {
      type: "introduction",
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Chez ${process.env.REACT_APP_COMPANY_NAME || "Nom de l'entreprise"}, nous nous engageons à protéger votre vie privée. Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons les informations lorsque vous utilisez notre application mobile.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "1. Collecte des informations" },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `${process.env.REACT_APP_APP_NAME || "Nom de l'application"} ne collecte pas de données personnelles sensibles telles que votre nom, adresse e-mail ou numéro de téléphone.`
            }
          ]
        },
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `L'application utilise ${process.env.REACT_APP_STORAGE_LIBRARY || 'WatermelonDB'} pour stocker localement des informations utilisateur. Ces données ne sont pas envoyées à des serveurs externes et restent sur votre appareil.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "2. Publicité et consentement" },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Notre application affiche des publicités via ${process.env.REACT_APP_AD_PROVIDER || 'Google AdMob'}. Nous utilisons la plateforme Google User Messaging Platform (UMP) pour recueillir votre consentement selon les réglementations en vigueur (RGPD en Europe, CCPA en Californie, PIPEDA au Canada, etc.).`
            }
          ]
        },
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: "Lors de votre première utilisation, un formulaire de consentement s'affichera pour vous permettre d'accepter ou refuser les annonces personnalisées. Vous pouvez modifier votre choix à tout moment via le bouton 'Gérer mes préférences publicitaires' dans les paramètres de l'application."
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "3. Utilisation des cookies et identifiants publicitaires" },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: "Nous utilisons des identifiants publicitaires et des technologies similaires pour améliorer votre expérience et optimiser nos publicités. Vous pouvez gérer ces paramètres en désactivant la publicité personnalisée dans les paramètres de votre appareil."
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "4. Partage des données avec des tiers" },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Nous ne partageons aucune donnée personnelle avec des tiers. Cependant, les annonces affichées via ${process.env.REACT_APP_AD_PROVIDER || 'Google AdMob'} sont soumises à leur propre politique de confidentialité.`
            }
          ]
        },
        {
          type: "link",
          url: "https://policies.google.com/technologies/ads",
          children: [
            {
              type: "text",
              text: "Google AdMob's Privacy Policy"
            }
          ]
        },
      ]
    },
    {
      type: "section",
      attributes: { title: "5. Gestion et révocation du consentement" },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: "Vous pouvez modifier votre consentement à tout moment via le bouton 'Gérer mes préférences publicitaires' disponible dans les paramètres de l’application."
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "6. Vos droits selon votre pays de résidence" },
      content: [
        {
          type: "paragraph",
          children: [
            { type: "text", text: "🇪🇺 **Europe (RGPD)** : Accès, rectification, suppression, portabilité et opposition au traitement." },
            { type: "text", text: "🇨🇦 **Canada (PIPEDA)** : Accès, correction des données et refus de la publicité ciblée." },
            { type: "text", text: "🇦🇺 **Australie (Privacy Act 1988)** : Accès aux informations personnelles et demande de suppression." },
            { type: "text", text: "🇮🇳 **Inde (DPDP Act 2023)** : Droit de refuser la collecte et accès aux informations collectées." },
            { type: "text", text: "🇺🇸 **États-Unis (CCPA - Californie)** : Demande des informations collectées, refus de la vente de données personnelles et droit à la suppression." }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "7. Suppression et réinitialisation des données publicitaires" },
      content: [
        {
          type: "paragraph",
          children: [
            { type: "text", text: "Vous pouvez réinitialiser votre identifiant publicitaire via votre appareil :" },
            { type: "text", text: "- 📱 **Android** : Paramètres > Google > Annonces > Réinitialiser l’identifiant publicitaire." },
            { type: "text", text: "- 🍏 **iOS** : Paramètres > Confidentialité > Publicité > Réinitialiser l’identifiant publicitaire." }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: { title: "8. Contact" },
      content: [
        {
          type: "paragraph",
          children: [
            { type: "text", text: `Si vous avez des questions, contactez-nous à : ${process.env.REACT_APP_CONTACT_EMAIL || 'contact@exemple.com'}.` }
          ]
        }
      ]
    }
  ]
};

export default DSLPolicyDatas;