import * as THREE from "three";
import { Colors } from '../constants/Colors.js';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { useEffect, useRef } from 'react';

const CanvasThree = ({ location = null, loading, error }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const colorBackground = Colors.canvaBackground;
    const numberOfStars = location === '/' ? 300 : 100;
    let avatar, edges, torus, planet, stars = [];
    let animateLoaderId, animateSceneId;

    if (canvasRef.current) {
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      const renderer = new THREE.WebGLRenderer({
        canvas: canvasRef.current,
        antialias: true
      });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      camera.position.setZ(5);

      const controls = new OrbitControls(camera, renderer.domElement);

      if (loading || error) {
        const avatarGeometry = new THREE.BoxGeometry(1, 1, 1);
        const avatarMaterial = new THREE.MeshBasicMaterial({ color: colorBackground });
        avatar = new THREE.Mesh(avatarGeometry, avatarMaterial);
        scene.add(avatar);

        const edgesGeometry = new THREE.EdgesGeometry(avatarGeometry);
        const edgesMaterial = new THREE.LineBasicMaterial({ color: 0xffffff });
        edges = new THREE.LineSegments(edgesGeometry, edgesMaterial);
        scene.add(edges);

        const animateLoader = () => {
          animateLoaderId = requestAnimationFrame(animateLoader);
          avatar.rotation.x += 0.005;
          avatar.rotation.y += 0.0075;
          avatar.rotation.z += 0.005;
          edges.rotation.copy(avatar.rotation);
          controls.update();
          renderer.render(scene, camera);
        };
        animateLoader();
      }

      const addStar = () => {
        const geometry = new THREE.SphereGeometry(0.12, 64, 64);
        const material = new THREE.MeshStandardMaterial({
          color: 0xffffff,
          emissiveIntensity: 0.1
        });
        const star = new THREE.Mesh(geometry, material);
        const [x, y, z] = Array(3).fill().map(() => THREE.MathUtils.randFloatSpread(200));
        star.position.set(x, y, z);
        scene.add(star);
        stars.push(star);
      };

      if (!loading && !error) {
        if (avatar && edges) {
          scene.remove(avatar);
          scene.remove(edges);
          cancelAnimationFrame(animateLoaderId);
        }

        const geometry = new THREE.TorusGeometry(10, 0.7, 48, 100);
        const material = new THREE.MeshStandardMaterial({ color: 0xffffff });
        material.metalness = 0.1;
        material.roughness = 0.1;
        torus = new THREE.Mesh(geometry, material);
        location === "/" && scene.add(torus);

        const pointLight = new THREE.PointLight(0xffffff);
        pointLight.position.set(7, 7, 7);
        const ambientLight = new THREE.AmbientLight(0xffffff, 3);
        scene.add(pointLight, ambientLight);

        Array(numberOfStars).fill().forEach(addStar);

        const planetTexture = new THREE.TextureLoader().load(
          "./assets/Earth_Texture_Full.webp"
        );
        planet = new THREE.Mesh(
          new THREE.SphereGeometry(3, 32, 32),
          new THREE.MeshStandardMaterial({ map: planetTexture })
        );
        scene.add(planet);
        planet.position.z = 20;
        planet.position.setX(-20);

        const moveCamera = () => {
          const t = document.body.getBoundingClientRect().top;
          // planet.rotation.x += 0.05;
          // planet.rotation.y += 0.075;
          // planet.rotation.z += 0.05;
          planet.rotation.x += 0.011;
          planet.rotation.y += 0.011;
          // planet.rotation.z += 0.008;
          camera.position.z = 10 + t * -0.01;
          camera.position.x = t * -0.0002;
          camera.rotation.y = t * -0.0002;
        };
        document.body.onscroll = moveCamera;

        const animateScene = () => {
          animateSceneId = requestAnimationFrame(animateScene);
          torus.rotation.x += 0.001;
          torus.rotation.y += 0.001;
          controls.update();
          renderer.render(scene, camera);
        };
        animateScene();
      }

      return () => {
        if (animateLoaderId) cancelAnimationFrame(animateLoaderId);
        if (animateSceneId) cancelAnimationFrame(animateSceneId);
        document.body.onscroll = null;
        if (avatar) scene.remove(avatar);
        if (edges) scene.remove(edges);
        if (torus) scene.remove(torus);
        if (planet) scene.remove(planet);
        stars.forEach(star => scene.remove(star));
        renderer.dispose();
      };
    }
  }, [location, loading, error]);

  return <canvas id="bg" ref={canvasRef}></canvas>;
}

export default CanvasThree;
