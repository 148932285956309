import { useDatasContext } from '../../../context/DatasProvider.js';
import CanvasThree from '../../../components/CanvasThree.jsx';
import './index.scss';
import { memo } from 'react';
import Footer from '../../../components/Footer/index.jsx';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

const LegalMentions = () => {
    const { datas, isLoading, error } = useDatasContext();

    if (!datas.legalMentions || !datas.legalMentions.blocks) {
        return (
            <CanvasThree
                location={'/'}
                loading={isLoading.legalMentions ?? true}
                error={error.legalMentions ?? undefined}
            />
        );
    }

    return (
        <>
            <CanvasThree
                loading={isLoading.legalMentions}
                error={error.legalMentions}
            />
            <main className='notice-container'>
                <h1 className='notice-title'>{datas.legalMentions.title}</h1>
                {datas.legalMentions.blocks.map((section, index) => (<div key={index}>
                    <h2 style={{ marginBottom: '2rem' }}>{section.title}</h2>
                    <div style={{ width: '100%' }}>
                        <BlocksRenderer
                            blocks={{
                                paragraph: ({ children }) => (
                                    <div style={{
                                        width: '100%',
                                        marginBottom: '4rem'
                                    }}>{children.map((child) => (<p>{child}</p>))}</div>)
                            }}
                            content={section.content}
                        />
                    </div>
                </div>
                ))}

            </main>
            {isLoading.legalMentions || error.legalMentions ? null : <Footer />}
        </>
    );
};

export default memo(LegalMentions);